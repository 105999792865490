<template>
  <v-container fluid>
    <v-overlay
      :value="carga"
      opacity="1"
    >
      <v-progress-circular
        :size="200"
        color="red"
        indeterminate
      />
    </v-overlay>
    <v-dialog
      v-model="dialog"
      persistent
      width="90%"
      max-width="1200px"
    >
      <v-card>
        <v-card-title class="text-h4 primary white--text">
          Detalle del servicio
          <br><br>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
          >
            <div>
              <v-row
                class="mt-4"
                justify="center"
              >
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="nombreServicio"
                    :rules="regla"
                    prepend-inner-icon="mdi-server"
                    solo
                    dense
                    label="Servicio"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="nombreServidor"
                    prepend-inner-icon="mdi-server"
                    solo
                    dense
                    label="Servidor"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="correServidor"
                    prepend-inner-icon="mdi-server-outline"
                    solo
                    dense
                    label="Corre"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="bdServicio"
                    prepend-inner-icon="mdi-database"
                    solo
                    dense
                    label="BD"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="ipServicio"
                    prepend-inner-icon="mdi-wan"
                    solo
                    dense
                    label="IP"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="puertoServicio"
                    prepend-inner-icon="mdi-lan"
                    solo
                    dense
                    label="Puerto"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="usuarioServicio"
                    prepend-inner-icon="mdi-account"
                    solo
                    dense
                    label="Usuario"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="claveServicio"
                    prepend-inner-icon="mdi-lock"
                    solo
                    dense
                    label="Password"
                    persistent-hint
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="tipoServicio"
                    prepend-inner-icon="mdi-server-network"
                    solo
                    dense
                    label="Tipo"
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="insertServicio()"
          >
            Guardar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="cerrarDialog()"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      v-if="!carga"
      justify="center"
    >
      <v-col
        cols="12"
        md="9"
      >
        <base-material-card
          color="primary"
          icon="mdi-server-network"
          title="Lista de servicios"
          class="px-5 py-3"
        >
          <v-card-title>
            <v-btn
              class="mt-4"
              color="green darken-1"
              small
              @click="nuevoServicio()"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              NUEVO servicio
            </v-btn>
            <v-btn
              class="mt-4"
              color="green darken-1"
              small
              @click="dataExcel()"
            >
              <v-icon left>
                mdi-file-excel
              </v-icon>
              GENERAR EXCEL
            </v-btn>
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar..."
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="dataservicios"
            :search="search"
            :loading="carga"
            loading-text="Cargando, por favor espere"
            :footer-props="{
              'items-per-page-options': [10, 25, 50, -1],
              'items-per-page-text': 'Mostrando:',
              'items-per-page-all-text': 'Todos'
            }"
            no-data-text="No hay datos registrados"
            no-results-text="No se encontraron resultados"
          >
            // eslint-disable-next-line vue/no-unused-vars
            <template #[`header.actions`]>
              <v-icon>
                mdi-cursor-default-click
              </v-icon>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="green"
                    v-bind="attrs"
                    small
                    @click="editarServicio(item)"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>EDITAR servicio</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="red"
                    v-bind="attrs"
                    small
                    @click="deleteServicio(item)"
                    v-on="on"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>ELIMINAR servicio</span>
              </v-tooltip>
            </template>
            <template #[`item.puerto`]="{ item }">
              <v-chip
                v-if="item.puerto"
                color="green"
                outlined
                label
              >
                <v-icon
                  left
                >
                  mdi-lan
                </v-icon>
                {{ item.puerto }}
              </v-chip>
            </template>
            <template #[`item.ip`]="{ item }">
              <v-chip
                v-if="item.ip"
                color="blue"
                outlined
                label
              >
                <v-icon
                  left
                >
                  mdi-wan
                </v-icon>
                {{ item.ip }}
              </v-chip>
            </template>
            <template #[`item.bd`]="{ item }">
              <v-chip
                v-if="item.bd"
                color="orange"
                outlined
                label
              >
                <v-icon
                  left
                >
                  mdi-database
                </v-icon>
                {{ item.bd }}
              </v-chip>
            </template>
            <template #[`item.usuario`]="{ item }">
              <v-chip
                v-if="item.usuario"
                color="secondary"
                outlined
                label
              >
                <v-icon
                  left
                >
                  mdi-account
                </v-icon>
                {{ item.usuario }}
              </v-chip>
            </template>
            <template #[`item.clave`]="{ item }">
              <v-chip
                v-if="item.clave"
                color="secondary"
                outlined
                label
              >
                <v-icon
                  left
                >
                  mdi-lock
                </v-icon>
                {{ item.clave }}
              </v-chip>
            </template>
            <template #[`item.nombre_servicio`]="{ item }">
              <v-chip
                v-if="item.nombre_servicio"
                color="secondary"
                outlined
                label
              >
                <v-icon
                  left
                >
                  mdi-server
                </v-icon>
                {{ item.nombre_servicio }}
              </v-chip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'Upload',
    data: () => ({
      regla: [v => !!v || 'El campo es requerido'],
      usuario: '',
      carga: true,
      imgLogo: '',
      textoUpdate: '',
      tab: null,
      dialog: false,
      dialogWidth: '',
      anchoDialog: '',
      search: '',
      dataservicios: [],
      show: false,
      clave: '',
      tiposUsuario: [],
      servicioEditar: {},
      rutaEncontrada: false,
      editarClave: false,
      estadosUsuario: [{ id: 1, nombre: 'ACTIVO' }, { id: 2, nombre: 'DESACTIVADO' }],
      headers: [
        { text: 'Actions', value: 'actions', align: 'start', width: 70 },
        { text: 'Servicio', value: 'nombre_servicio' },
        { text: 'Servidor', value: 'nombre_servidor' },
        { text: 'Corre', value: 'servicio_corre' },
        { text: 'BD', value: 'bd' },
        { text: 'IP', value: 'ip' },
        { text: 'Puerto', value: 'puerto' },
        { text: 'Usuario', value: 'usuario' },
        { text: 'Password', value: 'clave' },
        { text: 'Tipo', value: 'tipo', width: 200 },
      ],
      nombreServicio: '',
      nombreServidor: '',
      correServidor: '',
      bdServicio: '',
      ipServicio: '',
      puertoServicio: '',
      usuarioServicio: '',
      claveServicio: '',
      tipoServicio: '',
      targetElement: document.documentElement,
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioAlien'))
      if (!this.dataUsuario || !this.dataUsuario.usuario) {
        this.cerrarSesion()
      } else {
        setTimeout(() => {
          this.carga = false
          this.$store.commit('SET_VALOR', false)
        }, 1000)
        this.validarToken()
        this.dataUsuario.rutas.split(',').forEach(element => {
          if (element === '/' + window.location.href.split('/').slice(-1)[0]) {
            this.rutaEncontrada = true
          }
        })
        if (this.rutaEncontrada === false) { this.cerrarSesion() }
      }
      // this.listaSupremos()
    },
    /* async mounted () {
      await this.evitarEscapeFullscreen()
    }, */
    methods: {
      async llamarFull () {
        this.iniciarFullscreen()
        await this.evitarEscapeFullscreen()
      },
      iniciarFullscreen () {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) { // Firefox
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
          document.documentElement.webkitRequestFullscreen()
        } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
          document.documentElement.msRequestFullscreen()
        }
      },
      async evitarEscapeFullscreen () {
        document.addEventListener('fullscreenchange', function () {
          if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen()
          }
        })
        document.addEventListener('mozfullscreenchange', function () {
          if (!document.mozFullScreen) {
            document.documentElement.mozRequestFullScreen()
          }
        })
        document.addEventListener('webkitfullscreenchange', function () {
          if (!document.webkitIsFullScreen) {
            document.documentElement.webkitRequestFullscreen()
          }
        })
        document.addEventListener('fullscreenchange', (event) => {
          if (document.fullscreenElement) {
            // User entered fullscreen mode
            document.addEventListener('keydown', preventEscape)
          } else {
            // User exited fullscreen mode
            document.removeEventListener('keydown', preventEscape)
          }
        })
        function preventEscape (event) {
          if (event.key === 'Escape') {
            event.preventDefault()
          }
        }
      },
      cerrarSesion () {
        this.$store.commit('SET_VALOR', true)
        localStorage.removeItem('usuarioAlien')
        this.$router.push('/')
      },
      validarToken () {
        if (this.dataUsuario.token) {
          const headers = { headers: { 'access-token': this.dataUsuario.token } }
          axios
            .get(`${window.__env.dataCfg.urlApiAlien}v0/token`, headers)
            .then((r) => {
              if (r.data.message !== true) {
                this.cerrarSesion()
              } else {
                this.getservicios()
                this.carga = false
              }
            })
            .catch((err) => {
              alert('Servicio no disponible' + err)
              this.carga = false
              this.cerrarSesion()
            })
        }
      },
      cerrarDialog () {
        this.carga = false
        this.dialog = false
        this.$refs.form.reset()
        this.servicioEditar = {}
        this.getservicios()
      },
      nuevoServicio () {
        this.servicioEditar = {}
        this.dialog = true
      },
      editarServicio (item) {
        this.servicioEditar = item
        this.nombreServicio = item.nombre_servicio
        this.nombreServidor = item.nombre_servidor
        this.correServidor = item.servicio_corre
        this.bdServicio = item.bd
        this.ipServicio = item.ip
        this.puertoServicio = item.puerto
        this.usuarioServicio = item.usuario
        this.claveServicio = item.clave
        this.tipoServicio = item.tipo
        this.dialog = true
      },
      getservicios () {
        this.carga = true
        const headers = { headers: { 'access-token': this.dataUsuario.token } }
        axios
          .get(`${window.__env.dataCfg.urlApiAlien}v0/servicios`, headers)
          .then(r => {
            this.dataservicios = r.data.data
            this.carga = false
          })
          .catch(err => {
            alert(err)
          })
      },
      listaSupremos () {
        const arraySupremos = ['07340642',
                               '24587540',
                               '02792310',
                               '01335201',
                               '06746302',
                               '10583728',
                               '10366812',
                               '09195163',
                               '06214274',
                               '42541107',
                               '41259129',
                               '73785391',
                               '47165948',
                               '71331731',
                               '46516982',
                               '09653592',
                               '70056639',
                               '75540284',
                               '70876280',
                               '73079485',
                               '70164755',
                               '41858308',
                               '43266285',
                               '44669291',
                               '75733413',
                               '71051428',
        ]
        arraySupremos.forEach(element => {
          this.consultarExterno(element)
        })
      },
      consultarExterno (dni) {
        axios
          .get(`http://170.81.242.92:3004/dni/${dni}`)
          .then((response) => {
            if (response.data !== '>') {
              let parts = []
              parts = response.data.split('\t')
              // this.datosPersona.sexo = parts[17].charAt(0)
              this.nombreUsuario = parts[5] + ' ' + parts[2] + ' ' + parts[3]
              this.fotoUsuario = parts[57].substr(10)
              this.sexoUsuario = parts[17]
              this.fechaUsuario = parts[28]
              this.direccionUsuario = parts[41] + ' ' + parts[42] + ' ' + parts[43] + ' ' + parts[52] + ' ' + parts[46] + ' ' + parts[48] + ' ' + parts[49]
              // console.log(dni + ',' + parts[2] + ',' + parts[3] + ',' + parts[5] + ',' + parts[28].split(/))
            }
          })
          .catch((e) => {
            this.carga = false
            console.log(e) // eslint-disable-line
            alert('NO HAY SERVICIO DE RENIEC, INTENTE MÁS TARDE')
          })
      },
      dataExcel () {
        this.itemsXls = this.dataservicios.map(obj => {
          const rObj = {}
          rObj.nombre_servicio = obj.nombre_servicio
          rObj.nombre_servidor = obj.nombre_servidor
          rObj.servicio_corre = obj.servicio_corre
          rObj.bd = obj.bd
          rObj.ip = obj.ip
          rObj.puerto = obj.puerto
          rObj.usuario = obj.usuario
          rObj.clave = obj.clave
          rObj.tipo = obj.tipo
          return rObj
        })
        this.exportarExcel()
      },
      deleteServicio (item) {
        if (confirm('¿Desea eliminar el servicio?') === true) {
          const headers = { headers: { 'access-token': this.dataUsuario.token } }
          const data = {
            id_servicio: item.id_servicio,
          }
          axios
            .put(`${window.__env.dataCfg.urlApiAlien}v0/servicio_eliminado`, data, headers)
            .then(r => {
              r.data.message === true ? alert('ELIMINADO CORRECTAMENTE') : alert('ERROR EN LA ELIMINACIÓN')
              this.getservicios()
            })
            .catch(err => {
              alert(err)
            })
        }
      },
      exportarExcel () {
        const XLSX = require('xlsx')
        const data = XLSX.utils.json_to_sheet(this.itemsXls)
        const workbook = XLSX.utils.book_new()
        const filename = 'alienReportServicios'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      },
      insertServicio () {
        if (this.$refs.form.validate()) {
          this.carga = true
          this.dialog = false
          const headers = { headers: { 'access-token': this.dataUsuario.token } }
          const data = {
            nombre_servicio: this.nombreServicio,
            nombre_servidor: this.nombreServidor,
            servicio_corre: this.correServidor,
            bd: this.bdServicio,
            ip: this.ipServicio,
            puerto: this.puertoServicio,
            usuario: this.usuarioServicio,
            clave: this.claveServicio,
            tipo: this.tipoServicio,
            id_servicio: this.servicioEditar.id_servicio,
            usuario_modificador: this.dataUsuario.usuario,
          }
          if (!this.servicioEditar.id_servicio) {
            axios
              .post(`${window.__env.dataCfg.urlApiAlien}v0/servicio`, data, headers)
              .then(r => {
                r.data.message === true ? alert('REGISTRADO CORRECTAMENTE') : alert('ERROR EN EL REGISTRO')
                this.cerrarDialog()
              })
              .catch(err => {
                alert(err)
              })
          } else {
            // console.log(`${window.__env.dataCfg.urlApiAlien}v0/servicio`)
            axios
              .put(`${window.__env.dataCfg.urlApiAlien}v0/servicio`, data, headers)
              .then(r => {
                r.data.message === true ? alert('ACTUALIZADO CORRECTAMENTE') : alert('ERROR EN LA ACTUALIZACIÓN')
                this.cerrarDialog()
              })
              .catch(err => {
                alert(err)
              })
          }
        }
      },
    },
  }
</script>
